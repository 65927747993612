import "../portfolioPage.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"
import { generateImgArray } from "../../../components/portfolio/utils"

import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import PatternLateral from "../../../components/patternLateral/PatternLateral"
import VimeoVideo from "../../../components/portfolio/VimeoVideo"
import Lightbox from "../../../components/portfolio/Lightbox"

import LogoTHU from "../../../svg/portfolio/logoTHU.svg"

export default function IndexPage({ data }) {
  const { t } = useTranslation()
  const images = data.images.edges

  return (
    <Layout territory={"art"}>
      <Seo title={"THU 2017"} />
      <PatternLateral territory="art" />
      <h2 className="sectionTitle basic">
        <LogoTHU className="portfolioFileTitle" id="logoTHU" />
      </h2>

      <div className="content portfolioFileContent">
        <div className="filmDetails">
          <span>
            <Trans>Opening Ceremony Video</Trans>
          </span>
          <span className="year">
            <Trans>2017</Trans>
          </span>
        </div>

        <VimeoVideo
          videoId="235523026"
          title={t("Opening Ceremony Video of THU 2017 Edition")}
          aspectRatio={34 / 80}
          autoplay={false}
        >
          <p>
            <Trans>The Epoch of Belief - Opening Ceremony Video</Trans>
          </p>
        </VimeoVideo>

        <VimeoVideo
          videoId="240968451"
          title={t("Making of video of THU 2017 Opening Ceremony Video")}
          aspectRatio={34 / 80}
          autoplay={false}
        >
          <p>
            <Trans>The Epoch of Belief - Making of</Trans>
          </p>
        </VimeoVideo>

        {/* <div className="subsectionTitle">
          <h2>
            <Trans>Unicorn man</Trans>
          </h2>
        </div>
        <Lightbox
          className="imgGrid twoXtwo"
          images={generateImgArray(images, 0, 3)}
        />

        <div className="subsectionTitle">
          <h2>
            <Trans>The city</Trans>
          </h2>
        </div>
        <Lightbox
          className="imgGrid twoXtwo"
          images={generateImgArray(images, 4, 7)}
        />

        <div className="subsectionTitle">
          <h2>
            <Trans>Colorscript</Trans>
          </h2>
        </div>
        <Lightbox
          className="imgGrid twoXtwo"
          images={generateImgArray(images, 10, 13)}
        />

        <div className="subsectionTitle">
          <h2>
            <Trans>Highlights</Trans>
          </h2>
        </div>
        <Lightbox
          className="imgGrid"
          images={generateImgArray(images, 14, 31)}
        />

        <Lightbox images={[images[32]]} /> */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: "/^portfolio/thu2017//" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          relativePath
          name
          publicURL
        }
      }
    }
  }
`
